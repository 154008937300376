import Vue from 'vue'
import VueRouter from 'vue-router'
import Website from '@/layouts/website.vue'
// import Management from '@/layouts/management.vue'
import store from '../store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path:'/',
      redirect: '/home'
    },
    {
      path:'/',
      name: '/',
      component: Website,
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/home'),
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/transactionProcess',
          name: 'transactionProcess',
          component: () => import('@/views/transactionProcess')
        },
        {
          path: '/hostedServices',
          name: 'hostedServices',
          component: () => import('@/views/hostedServices')
        },
        {
          path: '/database',
          name: 'database',
          component: () => import('@/views/database')
        },
        {
          path: '/databaseDetails',
          name: 'databaseDetails',
          component: () => import('@/views/database/details.vue')
        },
        {
          path: '/news',
          name: 'news',
          component: () => import('@/views/news')
        },
        {
          path: '/newsDetails',
          name: 'newsDetails',
          component: () => import('@/views/news/details.vue')
        },
        {
          path: '/shopping',
          name: 'shopping',
          component: () => import('@/views/shopping')
        },
        {
          path: '/shoppingDetails',
          name: 'shoppingDetails',
          component: () => import('@/views/shopping/details.vue')
        },
        {
          path: '/wantToBuy',
          name: 'wantToBuy',
          component: () => import('@/views/wantToBuy')
        },
        {
          path: '/patentPublication',
          name: 'patentPublication',
          component: () => import('@/views/patentPublication')
        },
        {
          path: '/patentApplication',
          name: 'patentApplication',
          component: () => import('@/views/patentApplication')
        },
        {
          path: '/aboutUs',
          name: 'aboutUs',
          component: () => import('@/views/aboutUs'),
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/personalCenter',
          name: 'personalCenter',
          component: () => import('@/views/personalCenter'),
          children: [
            {
              path: '/personalCenter/personalInformation',
              name: 'personalInformation',
              component: () => import('@/views/personalCenter/personalInformation.vue'),
            },
            {
              path: '/personalCenter/forSale',
              name: 'forSale',
              component: () => import('@/views/personalCenter/forSale.vue'),
            },
            {
              path: '/personalCenter/toExamine',
              name: 'toExamine',
              component: () => import('@/views/personalCenter/toExamine.vue'),
            },
            {
              path: '/personalCenter/collect',
              name: 'collect',
              component: () => import('@/views/personalCenter/collect.vue'),
            },
          ]
        }
      ]
    },
    {
      path:'/:pathMatch(.*)',
      redirect: '/home'
    }
  ],
  scrollBehavior(to, form, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

// 重复跳转报错
const originalReplace = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const homeLink = '/home'
const loginWhiteList = '/personalCenter'
// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path.includes(loginWhiteList)) {
    store.dispatch('isLogin').then(res => {
      if (res) {
        next()
      } else {
        next({ path: homeLink })
      }
    })
  } else {
    next()
  }
})

export default router 